<template>
  <Transition name="slide-fade" appear>
    <div class="w-full m-auto bg-white text-t3 container">
      <div class="p-12 rounded items-center relative md:mt-0">
        <div class="mb-16">
          <h2 class="text-xl font-bold dark:text-white">
            Personal Information
          </h2>
          <div
            class="flex flex-col md:grid md:grid-cols-4 gap-2 m-auto mt-6 items-center"
          >
            <div class="text-center row-span-3">
              <img
                v-if="user.profile_img && typeof user.profile_img === 'string'"
                :src="user.profile_img"
                class="w-48 h-48 m-auto border-t3-secondary border object-cover rounded-xl"
                alt=""
              />
              <img
                v-if="user.profile_img && typeof user.profile_img !== 'string'"
                id="output-image"
                class="w-48 h-48 m-auto border-t3-secondary border object-cover rounded-xl"
                alt=""
              />
              <label
                v-if="!user.profile_img"
                class="w-48 h-48 flex m-auto flex-col items-center px-4 py-12 bg-white text-blue rounded-lg tracking-wide border border-gray-300 border-dashed cursor-pointer hover:border-t3-teritiary hover:text-white-teritiary"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.0"
                  stroke="currentColor"
                  class="w-12 h-12"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                  />
                </svg>
                <span class="mt-2 text-base leading-normal"
                  >Add a Profile Image</span
                >
                <FileInput
                  id="inputfield"
                  ref="fileupload"
                  accept="image/*"
                  @change="attachmentLoad"
                  v-model="user.profile_img"
                  class="hidden"
                />
              </label>
              <button
                @click="user.profile_img = null"
                v-if="user.profile_img"
                class="text-sm text-t3-cancel mt-2"
              >
                X Pick Another
              </button>
            </div>
            <div class="form-floating">
              <input
                v-model="user.first_name"
                type="text"
                class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                placeholder="First Name"
                id="floatingInput1"
              />
              <label for="floatingInput1" class="text-gray-700"
                >First Name<span class="text-red-600">*</span></label
              >
            </div>
            <div class="form-floating">
              <input
                v-model="user.middle_name"
                type="text"
                class="form-control block w-full m-0 px-3 py-1 text-xl font-normal text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                placeholder="Middle Name"
                id="floatingInput2"
              />
              <label for="floatingInput2" class="text-gray-700"
                >Middle Name</label
              >
            </div>
            <div class="form-floating">
              <input
                v-model="user.last_name"
                type="text"
                class="form-control uppercase w-full m-0 px-3 py-1 text-xl font-normal text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                placeholder="Last Name"
                id="floatingInput3"
              />
              <label for="floatingInput3" class="text-gray-700"
                >Last Name<span class="text-red-600">*</span></label
              >
            </div>
            <div class="">
              <Datepicker
                id="datepick"
                v-model="user.dob"
                :enableTimePicker="false"
                :max-date="new Date('1/1/2012')"
                datePicker
                placeholder="DOB"
                class="form-control block w-full text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none"
              />
            </div>
            <div class="w-full">
              <select
                placeholder="Gender"
                class="form-select form-select-lg appearance-none block w-full px-4 py-4 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                aria-label="Default select example"
                v-model="user.gender"
                id="id_customer-gender"
              >
                <option value="" disabled>Gender</option>

                <option value="Male">Male</option>

                <option value="Female">Female</option>
              </select>
            </div>
            <div class="form-floating">
              <input
                v-model="user.phone_number"
                type="tel"
                class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                placeholder="Mobile Number"
                id="floatingInput5"
              />
              <label for="floatingInput5" class="text-gray-700"
                >Mobile Number<span class="text-red-600">*</span></label
              >
            </div>
            <select
              v-model="user.nationality"
              class="form-select form-select-lg appearance-none block w-full px-4 py-4 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
            >
              <option value="" disabled>Nationality</option>
              <option value="AF">Afghanistan</option>

              <option value="AX">Åland Islands</option>

              <option value="AL">Albania</option>

              <option value="DZ">Algeria</option>

              <option value="AS">American Samoa</option>

              <option value="AD">Andorra</option>

              <option value="AO">Angola</option>

              <option value="AI">Anguilla</option>

              <option value="AQ">Antarctica</option>

              <option value="AG">Antigua and Barbuda</option>

              <option value="AR">Argentina</option>

              <option value="AM">Armenia</option>

              <option value="AW">Aruba</option>

              <option value="AU">Australia</option>

              <option value="AT">Austria</option>

              <option value="AZ">Azerbaijan</option>

              <option value="BS">Bahamas</option>

              <option value="BH">Bahrain</option>

              <option value="BD">Bangladesh</option>

              <option value="BB">Barbados</option>

              <option value="BY">Belarus</option>

              <option value="BE">Belgium</option>

              <option value="BZ">Belize</option>

              <option value="BJ">Benin</option>

              <option value="BM">Bermuda</option>

              <option value="BT">Bhutan</option>

              <option value="BO">Bolivia</option>

              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>

              <option value="BA">Bosnia and Herzegovina</option>

              <option value="BW">Botswana</option>

              <option value="BV">Bouvet Island</option>

              <option value="BR">Brazil</option>

              <option value="IO">British Indian Ocean Territory</option>

              <option value="BN">Brunei</option>

              <option value="BG">Bulgaria</option>

              <option value="BF">Burkina Faso</option>

              <option value="BI">Burundi</option>

              <option value="CV">Cabo Verde</option>

              <option value="KH">Cambodia</option>

              <option value="CM">Cameroon</option>

              <option value="CA">Canada</option>

              <option value="KY">Cayman Islands</option>

              <option value="CF">Central African Republic</option>

              <option value="TD">Chad</option>

              <option value="CL">Chile</option>

              <option value="CN">China</option>

              <option value="CX">Christmas Island</option>

              <option value="CC">Cocos (Keeling) Islands</option>

              <option value="CO">Colombia</option>

              <option value="KM">Comoros</option>

              <option value="CG">Congo</option>

              <option value="CD">Congo (the Democratic Republic of the)</option>

              <option value="CK">Cook Islands</option>

              <option value="CR">Costa Rica</option>

              <option value="CI">Côte d&#x27;Ivoire</option>

              <option value="HR">Croatia</option>

              <option value="CU">Cuba</option>

              <option value="CW">Curaçao</option>

              <option value="CY">Cyprus</option>

              <option value="CZ">Czechia</option>

              <option value="DK">Denmark</option>

              <option value="DJ">Djibouti</option>

              <option value="DM">Dominica</option>

              <option value="DO">Dominican Republic</option>

              <option value="EC">Ecuador</option>

              <option value="EG">Egypt</option>

              <option value="SV">El Salvador</option>

              <option value="GQ">Equatorial Guinea</option>

              <option value="ER">Eritrea</option>

              <option value="EE">Estonia</option>

              <option value="SZ">Eswatini</option>

              <option value="ET">Ethiopia</option>

              <option value="FK">Falkland Islands (Malvinas)</option>

              <option value="FO">Faroe Islands</option>

              <option value="FJ">Fiji</option>

              <option value="FI">Finland</option>

              <option value="FR">France</option>

              <option value="GF">French Guiana</option>

              <option value="PF">French Polynesia</option>

              <option value="TF">French Southern Territories</option>

              <option value="GA">Gabon</option>

              <option value="GM">Gambia</option>

              <option value="GE">Georgia</option>

              <option value="DE">Germany</option>

              <option value="GH">Ghana</option>

              <option value="GI">Gibraltar</option>

              <option value="GR">Greece</option>

              <option value="GL">Greenland</option>

              <option value="GD">Grenada</option>

              <option value="GP">Guadeloupe</option>

              <option value="GU">Guam</option>

              <option value="GT">Guatemala</option>

              <option value="GG">Guernsey</option>

              <option value="GN">Guinea</option>

              <option value="GW">Guinea-Bissau</option>

              <option value="GY">Guyana</option>

              <option value="HT">Haiti</option>

              <option value="HM">Heard Island and McDonald Islands</option>

              <option value="VA">Holy See</option>

              <option value="HN">Honduras</option>

              <option value="HK">Hong Kong</option>

              <option value="HU">Hungary</option>

              <option value="IS">Iceland</option>

              <option value="IN">India</option>

              <option value="ID">Indonesia</option>

              <option value="IR">Iran</option>

              <option value="IQ">Iraq</option>

              <option value="IE">Ireland</option>

              <option value="IM">Isle of Man</option>

              <option value="IL">Israel</option>

              <option value="IT">Italy</option>

              <option value="JM">Jamaica</option>

              <option value="JP">Japan</option>

              <option value="JE">Jersey</option>

              <option value="JO">Jordan</option>

              <option value="KZ">Kazakhstan</option>

              <option value="KE">Kenya</option>

              <option value="KI">Kiribati</option>

              <option value="KW">Kuwait</option>

              <option value="KG">Kyrgyzstan</option>

              <option value="LA">Laos</option>

              <option value="LV">Latvia</option>

              <option value="LB">Lebanon</option>

              <option value="LS">Lesotho</option>

              <option value="LR">Liberia</option>

              <option value="LY">Libya</option>

              <option value="LI">Liechtenstein</option>

              <option value="LT">Lithuania</option>

              <option value="LU">Luxembourg</option>

              <option value="MO">Macao</option>

              <option value="MG">Madagascar</option>

              <option value="MW">Malawi</option>

              <option value="MY">Malaysia</option>

              <option value="MV">Maldives</option>

              <option value="ML">Mali</option>

              <option value="MT">Malta</option>

              <option value="MH">Marshall Islands</option>

              <option value="MQ">Martinique</option>

              <option value="MR">Mauritania</option>

              <option value="MU">Mauritius</option>

              <option value="YT">Mayotte</option>

              <option value="MX">Mexico</option>

              <option value="FM">Micronesia (Federated States of)</option>

              <option value="MD">Moldova</option>

              <option value="MC">Monaco</option>

              <option value="MN">Mongolia</option>

              <option value="ME">Montenegro</option>

              <option value="MS">Montserrat</option>

              <option value="MA">Morocco</option>

              <option value="MZ">Mozambique</option>

              <option value="MM">Myanmar</option>

              <option value="NA">Namibia</option>

              <option value="NR">Nauru</option>

              <option value="NP">Nepal</option>

              <option value="NL">Netherlands</option>

              <option value="NC">New Caledonia</option>

              <option value="NZ">New Zealand</option>

              <option value="NI">Nicaragua</option>

              <option value="NE">Niger</option>

              <option value="NG">Nigeria</option>

              <option value="NU">Niue</option>

              <option value="NF">Norfolk Island</option>

              <option value="KP">North Korea</option>

              <option value="MK">North Macedonia</option>

              <option value="MP">Northern Mariana Islands</option>

              <option value="NO">Norway</option>

              <option value="OM">Oman</option>

              <option value="PK">Pakistan</option>

              <option value="PW">Palau</option>

              <option value="PS">Palestine, State of</option>

              <option value="PA">Panama</option>

              <option value="PG">Papua New Guinea</option>

              <option value="PY">Paraguay</option>

              <option value="PE">Peru</option>

              <option value="PH">Philippines</option>

              <option value="PN">Pitcairn</option>

              <option value="PL">Poland</option>

              <option value="PT">Portugal</option>

              <option value="PR">Puerto Rico</option>

              <option value="QA">Qatar</option>

              <option value="RE">Réunion</option>

              <option value="RO">Romania</option>

              <option value="RU">Russia</option>

              <option value="RW">Rwanda</option>

              <option value="BL">Saint Barthélemy</option>

              <option value="SH">
                Saint Helena, Ascension and Tristan da Cunha
              </option>

              <option value="KN">Saint Kitts and Nevis</option>

              <option value="LC">Saint Lucia</option>

              <option value="MF">Saint Martin (French part)</option>

              <option value="PM">Saint Pierre and Miquelon</option>

              <option value="VC">Saint Vincent and the Grenadines</option>

              <option value="WS">Samoa</option>

              <option value="SM">San Marino</option>

              <option value="ST">Sao Tome and Principe</option>

              <option value="SA">Saudi Arabia</option>

              <option value="SN">Senegal</option>

              <option value="RS">Serbia</option>

              <option value="SC">Seychelles</option>

              <option value="SL">Sierra Leone</option>

              <option value="SG">Singapore</option>

              <option value="SX">Sint Maarten (Dutch part)</option>

              <option value="SK">Slovakia</option>

              <option value="SI">Slovenia</option>

              <option value="SB">Solomon Islands</option>

              <option value="SO">Somalia</option>

              <option value="ZA">South Africa</option>

              <option value="GS">
                South Georgia and the South Sandwich Islands
              </option>

              <option value="KR">South Korea</option>

              <option value="SS">South Sudan</option>

              <option value="ES">Spain</option>

              <option value="LK">Sri Lanka</option>

              <option value="SD">Sudan</option>

              <option value="SR">Suriname</option>

              <option value="SJ">Svalbard and Jan Mayen</option>

              <option value="SE">Sweden</option>

              <option value="CH">Switzerland</option>

              <option value="SY">Syria</option>

              <option value="TW">Taiwan</option>

              <option value="TJ">Tajikistan</option>

              <option value="TZ">Tanzania</option>

              <option value="TH">Thailand</option>

              <option value="TL">Timor-Leste</option>

              <option value="TG">Togo</option>

              <option value="TK">Tokelau</option>

              <option value="TO">Tonga</option>

              <option value="TT">Trinidad and Tobago</option>

              <option value="TN">Tunisia</option>

              <option value="TR">Turkey</option>

              <option value="TM">Turkmenistan</option>

              <option value="TC">Turks and Caicos Islands</option>

              <option value="TV">Tuvalu</option>

              <option value="UG">Uganda</option>

              <option value="UA">Ukraine</option>

              <option value="AE">United Arab Emirates</option>

              <option value="GB">United Kingdom</option>

              <option value="UM">United States Minor Outlying Islands</option>

              <option value="US">United States of America</option>

              <option value="UY">Uruguay</option>

              <option value="UZ">Uzbekistan</option>

              <option value="VU">Vanuatu</option>

              <option value="VE">Venezuela</option>

              <option value="VN">Vietnam</option>

              <option value="VG">Virgin Islands (British)</option>

              <option value="VI">Virgin Islands (U.S.)</option>

              <option value="WF">Wallis and Futuna</option>

              <option value="EH">Western Sahara</option>

              <option value="YE">Yemen</option>

              <option value="ZM">Zambia</option>

              <option value="ZW">Zimbabwe</option>
            </select>
            <div class="form-floating col-span-2">
              <input
                v-model="user.email"
                type="email"
                class="form-control w-full m-0 px-3 py-1 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                placeholder="example@email.com"
                id="floatingInput4"
              />
              <label for="floatingInput4" class="text-gray-700"
                >Email<span class="text-red-600">*</span></label
              >
            </div>
          </div>
        </div>
        <div class="mb-16 grid grid-cols-2 gap-6">
          <div>
            <h2 class="text-xl font-bold dark:text-white">
              Additional Booking Infromation
            </h2>
            <div class="space-y-4 pt-4">
              <select
                v-model="user.license_type"
                class="form-select form-select-lg appearance-none block w-full px-4 py-4 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              >
                <option :value="null" disabled>License Type</option>
                <option v-for="typ in types" :key="typ.id" :value="typ.id">
                  {{ typ.name }}
                </option>
              </select>
              <select
                v-model="user.issuing_country"
                class="form-select form-select-lg appearance-none block w-full px-4 py-4 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              >
                <option value="" disabled>Country of issue</option>
                <option value="AF">Afghanistan</option>

                <option value="AX">Åland Islands</option>

                <option value="AL">Albania</option>

                <option value="DZ">Algeria</option>

                <option value="AS">American Samoa</option>

                <option value="AD">Andorra</option>

                <option value="AO">Angola</option>

                <option value="AI">Anguilla</option>

                <option value="AQ">Antarctica</option>

                <option value="AG">Antigua and Barbuda</option>

                <option value="AR">Argentina</option>

                <option value="AM">Armenia</option>

                <option value="AW">Aruba</option>

                <option value="AU">Australia</option>

                <option value="AT">Austria</option>

                <option value="AZ">Azerbaijan</option>

                <option value="BS">Bahamas</option>

                <option value="BH">Bahrain</option>

                <option value="BD">Bangladesh</option>

                <option value="BB">Barbados</option>

                <option value="BY">Belarus</option>

                <option value="BE">Belgium</option>

                <option value="BZ">Belize</option>

                <option value="BJ">Benin</option>

                <option value="BM">Bermuda</option>

                <option value="BT">Bhutan</option>

                <option value="BO">Bolivia</option>

                <option value="BQ">Bonaire, Sint Eustatius and Saba</option>

                <option value="BA">Bosnia and Herzegovina</option>

                <option value="BW">Botswana</option>

                <option value="BV">Bouvet Island</option>

                <option value="BR">Brazil</option>

                <option value="IO">British Indian Ocean Territory</option>

                <option value="BN">Brunei</option>

                <option value="BG">Bulgaria</option>

                <option value="BF">Burkina Faso</option>

                <option value="BI">Burundi</option>

                <option value="CV">Cabo Verde</option>

                <option value="KH">Cambodia</option>

                <option value="CM">Cameroon</option>

                <option value="CA">Canada</option>

                <option value="KY">Cayman Islands</option>

                <option value="CF">Central African Republic</option>

                <option value="TD">Chad</option>

                <option value="CL">Chile</option>

                <option value="CN">China</option>

                <option value="CX">Christmas Island</option>

                <option value="CC">Cocos (Keeling) Islands</option>

                <option value="CO">Colombia</option>

                <option value="KM">Comoros</option>

                <option value="CG">Congo</option>

                <option value="CD">
                  Congo (the Democratic Republic of the)
                </option>

                <option value="CK">Cook Islands</option>

                <option value="CR">Costa Rica</option>

                <option value="CI">Côte d&#x27;Ivoire</option>

                <option value="HR">Croatia</option>

                <option value="CU">Cuba</option>

                <option value="CW">Curaçao</option>

                <option value="CY">Cyprus</option>

                <option value="CZ">Czechia</option>

                <option value="DK">Denmark</option>

                <option value="DJ">Djibouti</option>

                <option value="DM">Dominica</option>

                <option value="DO">Dominican Republic</option>

                <option value="EC">Ecuador</option>

                <option value="EG">Egypt</option>

                <option value="SV">El Salvador</option>

                <option value="GQ">Equatorial Guinea</option>

                <option value="ER">Eritrea</option>

                <option value="EE">Estonia</option>

                <option value="SZ">Eswatini</option>

                <option value="ET">Ethiopia</option>

                <option value="FK">Falkland Islands (Malvinas)</option>

                <option value="FO">Faroe Islands</option>

                <option value="FJ">Fiji</option>

                <option value="FI">Finland</option>

                <option value="FR">France</option>

                <option value="GF">French Guiana</option>

                <option value="PF">French Polynesia</option>

                <option value="TF">French Southern Territories</option>

                <option value="GA">Gabon</option>

                <option value="GM">Gambia</option>

                <option value="GE">Georgia</option>

                <option value="DE">Germany</option>

                <option value="GH">Ghana</option>

                <option value="GI">Gibraltar</option>

                <option value="GR">Greece</option>

                <option value="GL">Greenland</option>

                <option value="GD">Grenada</option>

                <option value="GP">Guadeloupe</option>

                <option value="GU">Guam</option>

                <option value="GT">Guatemala</option>

                <option value="GG">Guernsey</option>

                <option value="GN">Guinea</option>

                <option value="GW">Guinea-Bissau</option>

                <option value="GY">Guyana</option>

                <option value="HT">Haiti</option>

                <option value="HM">Heard Island and McDonald Islands</option>

                <option value="VA">Holy See</option>

                <option value="HN">Honduras</option>

                <option value="HK">Hong Kong</option>

                <option value="HU">Hungary</option>

                <option value="IS">Iceland</option>

                <option value="IN">India</option>

                <option value="ID">Indonesia</option>

                <option value="IR">Iran</option>

                <option value="IQ">Iraq</option>

                <option value="IE">Ireland</option>

                <option value="IM">Isle of Man</option>

                <option value="IL">Israel</option>

                <option value="IT">Italy</option>

                <option value="JM">Jamaica</option>

                <option value="JP">Japan</option>

                <option value="JE">Jersey</option>

                <option value="JO">Jordan</option>

                <option value="KZ">Kazakhstan</option>

                <option value="KE">Kenya</option>

                <option value="KI">Kiribati</option>

                <option value="KW">Kuwait</option>

                <option value="KG">Kyrgyzstan</option>

                <option value="LA">Laos</option>

                <option value="LV">Latvia</option>

                <option value="LB">Lebanon</option>

                <option value="LS">Lesotho</option>

                <option value="LR">Liberia</option>

                <option value="LY">Libya</option>

                <option value="LI">Liechtenstein</option>

                <option value="LT">Lithuania</option>

                <option value="LU">Luxembourg</option>

                <option value="MO">Macao</option>

                <option value="MG">Madagascar</option>

                <option value="MW">Malawi</option>

                <option value="MY">Malaysia</option>

                <option value="MV">Maldives</option>

                <option value="ML">Mali</option>

                <option value="MT">Malta</option>

                <option value="MH">Marshall Islands</option>

                <option value="MQ">Martinique</option>

                <option value="MR">Mauritania</option>

                <option value="MU">Mauritius</option>

                <option value="YT">Mayotte</option>

                <option value="MX">Mexico</option>

                <option value="FM">Micronesia (Federated States of)</option>

                <option value="MD">Moldova</option>

                <option value="MC">Monaco</option>

                <option value="MN">Mongolia</option>

                <option value="ME">Montenegro</option>

                <option value="MS">Montserrat</option>

                <option value="MA">Morocco</option>

                <option value="MZ">Mozambique</option>

                <option value="MM">Myanmar</option>

                <option value="NA">Namibia</option>

                <option value="NR">Nauru</option>

                <option value="NP">Nepal</option>

                <option value="NL">Netherlands</option>

                <option value="NC">New Caledonia</option>

                <option value="NZ">New Zealand</option>

                <option value="NI">Nicaragua</option>

                <option value="NE">Niger</option>

                <option value="NG">Nigeria</option>

                <option value="NU">Niue</option>

                <option value="NF">Norfolk Island</option>

                <option value="KP">North Korea</option>

                <option value="MK">North Macedonia</option>

                <option value="MP">Northern Mariana Islands</option>

                <option value="NO">Norway</option>

                <option value="OM">Oman</option>

                <option value="PK">Pakistan</option>

                <option value="PW">Palau</option>

                <option value="PS">Palestine, State of</option>

                <option value="PA">Panama</option>

                <option value="PG">Papua New Guinea</option>

                <option value="PY">Paraguay</option>

                <option value="PE">Peru</option>

                <option value="PH">Philippines</option>

                <option value="PN">Pitcairn</option>

                <option value="PL">Poland</option>

                <option value="PT">Portugal</option>

                <option value="PR">Puerto Rico</option>

                <option value="QA">Qatar</option>

                <option value="RE">Réunion</option>

                <option value="RO">Romania</option>

                <option value="RU">Russia</option>

                <option value="RW">Rwanda</option>

                <option value="BL">Saint Barthélemy</option>

                <option value="SH">
                  Saint Helena, Ascension and Tristan da Cunha
                </option>

                <option value="KN">Saint Kitts and Nevis</option>

                <option value="LC">Saint Lucia</option>

                <option value="MF">Saint Martin (French part)</option>

                <option value="PM">Saint Pierre and Miquelon</option>

                <option value="VC">Saint Vincent and the Grenadines</option>

                <option value="WS">Samoa</option>

                <option value="SM">San Marino</option>

                <option value="ST">Sao Tome and Principe</option>

                <option value="SA">Saudi Arabia</option>

                <option value="SN">Senegal</option>

                <option value="RS">Serbia</option>

                <option value="SC">Seychelles</option>

                <option value="SL">Sierra Leone</option>

                <option value="SG">Singapore</option>

                <option value="SX">Sint Maarten (Dutch part)</option>

                <option value="SK">Slovakia</option>

                <option value="SI">Slovenia</option>

                <option value="SB">Solomon Islands</option>

                <option value="SO">Somalia</option>

                <option value="ZA">South Africa</option>

                <option value="GS">
                  South Georgia and the South Sandwich Islands
                </option>

                <option value="KR">South Korea</option>

                <option value="SS">South Sudan</option>

                <option value="ES">Spain</option>

                <option value="LK">Sri Lanka</option>

                <option value="SD">Sudan</option>

                <option value="SR">Suriname</option>

                <option value="SJ">Svalbard and Jan Mayen</option>

                <option value="SE">Sweden</option>

                <option value="CH">Switzerland</option>

                <option value="SY">Syria</option>

                <option value="TW">Taiwan</option>

                <option value="TJ">Tajikistan</option>

                <option value="TZ">Tanzania</option>

                <option value="TH">Thailand</option>

                <option value="TL">Timor-Leste</option>

                <option value="TG">Togo</option>

                <option value="TK">Tokelau</option>

                <option value="TO">Tonga</option>

                <option value="TT">Trinidad and Tobago</option>

                <option value="TN">Tunisia</option>

                <option value="TR">Turkey</option>

                <option value="TM">Turkmenistan</option>

                <option value="TC">Turks and Caicos Islands</option>

                <option value="TV">Tuvalu</option>

                <option value="UG">Uganda</option>

                <option value="UA">Ukraine</option>

                <option value="AE">United Arab Emirates</option>

                <option value="GB">United Kingdom</option>

                <option value="UM">United States Minor Outlying Islands</option>

                <option value="US">United States of America</option>

                <option value="UY">Uruguay</option>

                <option value="UZ">Uzbekistan</option>

                <option value="VU">Vanuatu</option>

                <option value="VE">Venezuela</option>

                <option value="VN">Vietnam</option>

                <option value="VG">Virgin Islands (British)</option>

                <option value="VI">Virgin Islands (U.S.)</option>

                <option value="WF">Wallis and Futuna</option>

                <option value="EH">Western Sahara</option>

                <option value="YE">Yemen</option>

                <option value="ZM">Zambia</option>

                <option value="ZW">Zimbabwe</option>
              </select>
              <div class="">
                <Datepicker
                  id="datepick"
                  v-model="user.license_validity"
                  :enableTimePicker="false"
                  :min-date="new Date()"
                  datePicker
                  placeholder="License Validity"
                  class="form-control block w-full text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none"
                />
              </div>
              <div class="form-floating">
                <input
                  v-model="user.license_number"
                  type="text"
                  class="form-control block w-full m-0 px-3 py-1 text-xl font-normal text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out focus:outline-none"
                  placeholder="License Numer"
                  id="floatingInput7"
                />
                <label for="floatingInput7" class="text-gray-700"
                  >License Number</label
                >
              </div>
              <select
                v-model="user.rt_level"
                class="form-select form-select-lg appearance-none block w-full px-4 py-4 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              >
                <option value="" disabled>RT Level</option>
                <option value="VFR">VFR</option>
                <option value="IFR">IFR</option>
                <option value="N/A">N/A</option>
              </select>
            </div>
          </div>
          <div>
            <h2 class="text-xl font-bold dark:text-white">
              Discounts and Affiliation
            </h2>
            <div class="space-y-4 pt-4">
              <select
                @change="customerOptions()"
                v-model="user.customer_company"
                class="form-select form-select-lg appearance-none block w-full px-4 py-4 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              >
                <option value="">Organization</option>
                <option
                  v-for="organization in organizations"
                  :key="organization.id"
                  :value="organization.id"
                >
                  {{ organization.organization_name }}
                </option>
              </select>
              <select
                v-model="user.party"
                class="form-select form-select-lg appearance-none block w-full px-4 py-4 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              >
                <option value="">Party</option>
                <option value="individual">Individual</option>
                <option value="company">Company</option>
              </select>
              <div class="w-full flex flex-col items-center mx-auto">
                <div class="w-full">
                  <div class="flex flex-col items-center relative">
                    <div class="w-full">
                      <div
                        @click="showAff = !showAff"
                        class="hover:bg-sky-200 hover:bg-sky-200 hover:text-t3-secondary border cursor-pointer text-t3 items-center p-4 flex border-gray-200 bg-white rounded justify-between"
                      >
                        <p>{{ user.other_affiliations.length }} Selected</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="3"
                          stroke="currentColor"
                          class="w-3 h-3 transform"
                          :class="{ 'rotate-180': showAff }"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </div>
                    </div>
                    <div
                      v-if="showAff"
                      class="shadow top-16 bg-white z-40 w-full absolute rounded max-h-select overflow-y-auto svelte-5uyqqj"
                    >
                      <div
                        v-for="organization in organizations"
                        :key="organization.id"
                        class="flex flex-col w-full"
                      >
                        <div
                          class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-t3-body hover:text-t3-secondary"
                        >
                          <div
                            class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100"
                          >
                            <div class="w-full items-center flex">
                              <input
                                @change="editChange(organization.id)"
                                :checked="
                                  user.other_affiliations.find(
                                    (a) => a === organization.id
                                  )
                                "
                                class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                type="checkbox"
                                id="flexCheckDefault"
                              />
                              <div class="mx-2 leading-6">
                                {{ organization.organization_name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <select
                v-model="user.discount_rate"
                class="form-select form-select-lg appearance-none block w-full px-4 py-4 font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              >
                <option disabled :value="null">
                  Select Discount Rate (Current: Company Standard)
                </option>
                <option
                  v-for="discount in optionsList"
                  :value="discount.id"
                  :key="discount.id"
                >
                  {{ discount.name }} {{ discount.discount_rate }}%
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="mb-16">
          <h2 v-if="user.documents.length > 0" class="text-xl font-bold dark:text-white">
            Uploaded Documents
          </h2>
          <div class="flex gap-8 py-4">
            <a :href="user.documents[0].document" target="_blank" class="p-2 shadow-xl text-t3-mutedText flex gap-2 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-t3-secondary">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
              </svg>
              <div class="space-y-1">
                <h1 class="font-bold">{{ user.documents[0].name }}</h1>
                <p>Passport</p>
              </div>
            </a>
            <a :href="user.documents[1].document" target="_blank" class="p-2 shadow-xl text-t3-mutedText flex gap-2 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-t3-secondary">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
              </svg>
              <div class="space-y-1">
                <h1 class="font-bold">{{ user.documents[1].name }}</h1>
                <p>License</p>
              </div>
            </a>
            <a v-if="user.documents.length > 2" :href="user.documents[2].document" target="_blank" class="p-2 shadow-xl text-t3-mutedText flex gap-2 items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12 text-t3-secondary">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
              </svg>
              <div class="space-y-1">
                <h1 class="font-bold">{{ user.documents[2].name }}</h1>
                <p>Organisation ID</p>
              </div>
            </a>
          </div>
        </div>

        <div class="w-full items-center bg-gray-100 my-16 p-6 rounded justify-between flex">
          <div>
            <h1 clas="text-xl text-t3">
              <span class="font-bold">Requested Slot:</span><span v-if="user.slot_time"> {{ user.slot_time }} {{ new Date(user.slot_date).toLocaleDateString("en-GB", { year: "numeric", month: "short", day: "numeric",}) }}</span>
              <span v-if="!user.slot_time">The slot requested by the customer is either already booked or does not exist. <br> Please update the request with the next available slot</span>
            </h1>
            <button id="ww" type="button" class="text-t3-secondary" data-bs-toggle="modal" data-bs-target="#rescheduling">
              Update Slot
            </button>
          </div>
          <div class="flex gap-2">
            <button
              class="w-48 rounded-md font-medium border border-red-500 p-3 text-red-500 hover:text-white hover:bg-red-500 block"
            >
              Decline
            </button>
            <button
              @click="addUser()"
              class="w-48 rounded-md font-medium bg-t3 p-3 text-white hover:text-white hover:bg-t3-secondary block"
            >
              Approve
            </button>
          </div>
        </div>

        <div
          class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
          id="rescheduling"
          tabindex="-1"
          aria-labelledby="exampleModalCenterTitle"
          aria-modal="true"
          role="dialog"
        >
          <div
            class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
          >
            <div
              class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
            >
              <div
                class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
              >
                <h5
                  class="text-2xl leading-normal text-t3 font"
                  id="exampleModalScrollableLabel"
                >
                  Rescheduling Slot
                </h5>
                <button
                  type="button"
                  class="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body relative p-4">
                <label for="datepick" class="text-sm text-gray-400 mt-8"
                  >Highlighted dates have available slots
                </label>
                <Datepicker
                  :min-date="new Date()"
                  id="datepick"
                  @update:modelValue="newSlots"
                  v-model="user.slot_date"
                  :enableTimePicker="false"
                  :highlight="highlighted"
                  datePicker
                  placeholder="Select Date"
                  class="mb-8 form-control block w-full text-xl font-normal text-gray-700 bg-white rounded transition ease-in-out m-0 focus:outline-none"
                />
                <select
                  v-model="user.slot"
                  class="h-12 form-control block w-full p-3 text-xl overflow-y-scroll font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:outline-none"
                >
                  <option disabled :value="null">Select Slot</option>
                  <option
                    @click="user.slot_time = slot.start_time"
                    v-for="slot in freeSlots"
                    :value="slot.id"
                    :key="slot.id"
                  >
                    Date:{{
                      new Date(slot.date).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    }}
                    Time:{{ slot.start_time }}
                  </option>
                </select>
              </div>
              <div
                class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md"
              >
                <button
                  type="button"
                  id="close"
                  class="inline-block px-6 py-2.5 bg-t3-cancel text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-t3-cancel hover:shadow-lg focus:bg-t3-cancel focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                  data-bs-dismiss="modal"
                >
                  Close and Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import FileInput from "@/components/FileInput.vue";
import Datepicker from "@vuepic/vue-datepicker";
import addDays from "date-fns/addDays";
import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
} from "date-fns";
import "@vuepic/vue-datepicker/dist/main.css";
import { ref, watchEffect } from "vue";
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";
import ReportServices from "@/services/ReportServices";
import UsersServices from "../services/UsersServices";

export default {
  components: {
    Datepicker,
    FileInput,
  },
  setup() {
    const date = ref();
    const presetRanges = ref([
      {
        label: "Today",
        range: [new Date(), new Date()],
      },
      {
        label: "This month",
        range: [startOfMonth(new Date()), endOfMonth(new Date())],
      },
      {
        label: "Last month",
        range: [
          startOfMonth(subMonths(new Date(), 1)),
          endOfMonth(subMonths(new Date(), 1)),
        ],
      },
      {
        label: "This year",
        range: [startOfYear(new Date()), endOfYear(new Date())],
      },
    ]);
    const highlightedDates = ref([
      addDays(new Date(), 1),
      addDays(new Date(), 2),
      addDays(new Date(), 3),
    ]);

    return {
      date,
      highlightedDates,
      presetRanges,
    };
  },
  props: {
    loaded: {},
    id: {},
  },
  data() {
    return {
      highlighted: [],
      checker: false,
      selected: null,
      currentFile: null,
      showAff: false,
      optionsList: [],
      freeSlots: null,
      user: {
        slot: null,
        slot_date: new Date(),
        slot_time: null,
        email: null,
        profile_img: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        nationality: null,
        dob: null,
        gender: null,
        phone_number: null,
        documents: [],
        license_type: '',
        party: null,
        customer_company: null,
        other_affiliations: [],
        issuing_country: null,
        license_number: null,
        license_validity: null,
        rt_level: null,
        discount_rate: '',
      },
    };
  },

  methods: {
    ...mapActions({
      getorganizations: "Settings/getAllOrganizations",
      gettypes: "Settings/getAllCustomerTypes",
      getdiscounts: "Settings/getAllDiscounts",
      getslots: "Slots/getAllSlots",
      getslotD: "Slots/getSlotsForDate",
    }),
    disabledAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date > today;
    },
    newSlots(data) {
      if (data) {
        this.user.slot_date = data;
        let dat = this.user.slot_date.toISOString();
        this.getslotD(dat.substr(0, 10)).then(() => {
          if (this.slotD) {
            this.user.slot = null;
            this.freeSlots = this.slotD.filter(
              (slot) => slot.assessment === null
            );
          }
        });
      }
    },
    editChange(id) {
      if (this.user.other_affiliations.includes(id)) {
        this.user.other_affiliations.splice(
          this.user.other_affiliations.indexOf(id),
          1
        );
      } else {
        this.user.other_affiliations.push(id);
      }
      this.user.discount_rate = null;
      this.customerOptions();
    },
    attachmentLoad(event) {
      var reader = new FileReader();
      reader.onload = function () {
        var output = document.getElementById("output-image");
        output.setAttribute("src", URL.createObjectURL(event.target.files[0]));
        document.getElementById("output-image").load();
      };
      reader.readAsDataURL(event.target.files[0]);

      this.currentFile = event.target.value;
    },
    customerOptions() {
      let orgs = null;
      let disc = null;
      if (this.user.customer_company) {
        orgs = [this.user.customer_company];
        orgs = orgs.concat(this.user.other_affiliations);
      }

      if (orgs) {
        disc = [];
        orgs.forEach((org) => {
          disc = disc.concat(
            this.discounts.filter((d) => d.organization === org)
          );
        });
      }

      if (disc) {
        this.optionsList = disc;
      } else {
        this.user.discount_rate = null;
        this.optionsList = [];
      }
    },

    addUser() {
      this.checker = true;

      for (var i in this.user) {
        if (
          i !== "middle_name" &&
          i !== "complete_time" &&
          i !== "completed_by" &&
          i !== "rt_level" &&
          i !== "license_number" &&
          i !== "license_type" &&
          i !== "license_validity" &&
          i !== "issuing_country" &&
          i !== "discount_rate"
        ) {
          if (!this.user[i]) {
            this.checker = false;
          }
        }
      }
      if (this.checker) {
        const getFormData = (object) =>
          Object.entries(object).reduce((fd, [key, val]) => {
            if (Array.isArray(val)) {
              val.forEach((v) => fd.append(key, v));
            } else {
              fd.append(key, val);
            }
            return fd;
          }, new FormData());
        let sendObj = Object.assign({}, this.user);
        delete sendObj.complete_time;
        delete sendObj.completed_by;
        sendObj.dob = new Date(sendObj.dob)
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        sendObj.license_validity = new Date(sendObj.license_validity)
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })
          .split("/")
          .reverse()
          .join("-");
        if (typeof this.user.profile_img === "string")
          delete sendObj.profile_img;

        UsersServices.acceptEnquiry(this.id, getFormData(sendObj))
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: "Customer Request Accepted!",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timerProgressBar: true,
              timer: 3000,
            });
            this.$router.push({
              name: "AssessmentsTab",
              params: { currTab: "booked" },
            });
            this.$forceUpdate();
          })
          .catch((error) => {
            let message = Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)];
            let customer_message = error.response.data[Object.keys(error.response.data).at(0)];

            if (customer_message.includes("customer inquiry with this email already exists.")) {
              Swal.fire({
                icon: "info",
                title: "Duplicate Inquiry",
                text: "It appears you've already submitted a request. You will receive a conformation soon from our team as they process your inquiry.",
                showConfirmButton: true,
                confirmButtonColor: "#0E2D5B",
                confirmButtonText: "OK",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "It appears you have encountered an error!",
                text: message,
                showConfirmButton: true,
                showDenyButton: true,
                confirmButtonColor: "#0E2D5B",
                denyButtonColor: "#990c53",
                denyButtonText: "Ignore",
                confirmButtonText: "Send Report",
              }).then((result) => {
                if (result.value) {
                  ReportServices.sendErrorReport({
                    error_page: window.location.href,
                    error_api: error.response.config.url,
                    error_message: message,
                  });
                }
              });
            }
          });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Try again",
          text: "Please fill in all required the fields.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
      }
    },
  },

  computed: {
    ...mapState({
      slotD: (state) => state.Slots.slotD,
      slots: (state) => state.Slots.slots,
      organizations: (state) => state.Settings.organizations,
      types: (state) => state.Settings.customer_types,
      discounts: (state) => state.Settings.discounts,
    }),
  },

  created() {
    this.getslots().then(() => {
      this.freeSlots = this.slots.filter((slot) => slot.assessment === null);
    });
    if (this.user.slot_date) {
      let dat = this.user.slot_date.toISOString();
      this.getslotD(dat.substr(0, 10));
    } else {
      this.slotD = null;
    }
    if (this.slotD) {
      this.freeSlots = this.slotD.filter((slot) => slot.assessment === null);
    }

    this.getorganizations();
    UsersServices.getEnquiry(this.id).then((response) => {
      this.user = response.data;
      let dat = new Date(response.data.slot_date).toISOString();
      this.getslotD(dat.substr(0, 10)).then(() => {
        if (this.slotD) {
          this.freeSlots = this.slotD.filter(
            (slot) => slot.assessment === null
          );
        }
      });
      this.getdiscounts().then(() => {
        this.customerOptions();
      });
    });
    watchEffect(() => {
      if (this.slots) {
        this.highlighted = [];
        this.slots
          .filter((slot) => slot.assessment === null)
          .forEach((s) => {
            this.highlighted.push(new Date(s.date));
          });
      }
    });

    this.gettypes();
  },
};
</script>

<style scoped>
#search:focus + #list {
  display: flex;
}
.dp__input {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
#search:not(focus) + .lister {
  border: none;
}
.searcher:focus {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
#list:hover {
  display: flex;
}
.dp__main {
  padding: 0 !important;
}
</style>
